import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import CallIcon from '@mui/icons-material/Call';

const ConsoltoButton: FC = () => {
  const navigate = useNavigate();
  const chat = () => {
    // dispatch an event that will trigger one of the actions.
    navigate('/chat');
  };

  return (
    <Button
      variant="contained"
      onClick={chat}
      startIcon={<CallIcon />}
      sx={{
        backgroundImage: 'linear-gradient(to bottom right,#78bf00, #7be7c6)',
        fontWeight: 'bold',
        width: '100%',
        borderRadius: '50px',
      }}>
      ASISTENCIA EN LINEA
    </Button>
  );
};

export default ConsoltoButton;
