import { LanguageState } from '../types/languageTypes';
import { createSlice } from '@reduxjs/toolkit';
import { Action } from './base';

const initialState: LanguageState = {
  selected: 'es',
};

const LanguageSlice = createSlice({
  name: 'language',
  initialState,
  reducers: {
    changeLanguage: (state: LanguageState, action: Action) => {
      console.log('changeLanguage', action.payload);
      return {
        ...state,
        ...action.payload,
      };
    },
    resetLanguage: () => {
      return {
        ...initialState,
      };
    },
  },
});

export const languageActions = LanguageSlice.actions;
export const languageReducer = LanguageSlice.reducer;
