import { es } from './es';
import { en } from './en';
import { Language } from '../redux/types/languageTypes';

interface LanguageBook {
  [index: string]: any;
  es: Language;
  en: Language;
}

const languages: LanguageBook = {
  es: es,
  en: en,
};

export default languages;
