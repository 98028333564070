import React, { FC, ChangeEvent, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { RootState } from '../../redux/store';
import { useSelector } from 'react-redux';
import userService from '../../services/UserServices';
import Terms from '../Terms/Terms';
import { Term } from '../../redux/types/terms';
import { userActions } from '../../redux/slices/userSlice';
import termsService from '../../services/TermsServices';
import { Box, TextField, Button, Stack, Typography } from '@mui/material';
import { InputAdornment, Select, MenuItem, InputLabel, FormControl } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import { FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import logo_vrt from './../../images/logo_vrt.png';
import { dgv, formatValue } from './AddRUT';
import SwipeableViews from 'react-swipeable-views';
import { Stepper, Step, StepLabel } from '@mui/material';
import DateInput from '../EABCharts/DateInput';
import { useNavigate } from 'react-router-dom';

const steps = ['Info. Personal', 'Info. de Salud', 'Terminos de uso'];

const UserRegister: FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [RUT, setRUT] = useState('');
  const [RUTError, setRUTError] = useState('');
  const [name, setName] = useState('');
  const [lastName, setLastName] = useState('');
  const [password, setPassword] = useState('');
  const [confirmation, setConfirmation] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [weight, setWeight] = useState('');
  const [height, setHeight] = useState('');
  const [birthday, setBirthday] = useState<string | null>(null);
  const [sex, setSex] = useState('');
  const [diabetes, setDiabetes] = useState(false);
  const [thrombosis, setThrombosis] = useState(false);
  const [heartDisease, setHeartDisease] = useState(false);
  const [otherDiseases, setOtherDiseases] = useState('');
  const [terms, setTerms] = useState<Term | null>(null);
  const [accept, setAccept] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [phone, setPhone] = useState('569');
  const [phoneError, setPhoneError] = useState('');
  const { token } = useSelector((state: RootState) => state.business);
  useEffect(() => {
    termsService.getTerms().then((response) => setTerms(response));
  }, []);

  const changeRUT = async (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value } = event.target;
    const formattedValue = formatValue(value);
    const checkDigit = dgv(+formattedValue.slice(0, -2));
    let validRut = '';
    if (checkDigit.toString() !== formattedValue.charAt(formattedValue.length - 1)) {
      validRut = 'El RUT entregado no es válido';
    }
    setRUT(formattedValue);
    setRUTError(validRut);
    const response = await userService.isRegistered(formattedValue);
    if (response.user !== null) {
      setRUTError('El RUT ingresado ya está registrado');
    }
  };
  const changeName = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setName(event.target.value);
  };
  const changeLastName = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setLastName(event.target.value);
  };
  const changePassword = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const password = event.target.value;
    setPassword(password);

    if (password.length < 6) {
      setPasswordError('La contraseña debe tener al menos 6 caracteres');
    } else if (confirmation !== password) {
      setPasswordError('Las contraseñas no coinciden');
    } else {
      setPasswordError('');
    }
  };
  const changeConfirmation = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setConfirmation(event.target.value);
    if (password !== event.target.value) {
      setPasswordError('Las contraseñas no coinciden');
    } else if (event.target.value.length < 6) {
      setPasswordError('La contraseña debe tener al menos 6 caracteres');
    } else {
      setPasswordError('');
    }
  };
  const changeHeight = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const regex = /^[0-9\b]+$/;
    if (event.target.value === '' || regex.test(event.target.value)) {
      setHeight(event.target.value);
    }
  };
  const changeWeight = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const regex = /^[0-9\b]+$/;
    if (event.target.value === '' || regex.test(event.target.value)) {
      setWeight(event.target.value);
    }
  };
  const changeBirthday = (event: string | null) => {
    setBirthday(event);
  };
  const changeOtherDiseases = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setOtherDiseases(event.target.value);
  };

  const changeSex = (event: SelectChangeEvent) => {
    setSex(event.target.value as string);
  };
  const register = async () => {
    const response = await userService.createUser(
      {
        email: '',
        rut: RUT,
        password,
        first_name: name,
        last_name: lastName,
        height: +height,
        weight: +weight,
        birthday: birthday ? birthday : '',
        sex,
        diabetes,
        heart_diseases: heartDisease,
        other_diseases: otherDiseases,
        thrombosis,
        business_id: 17,
        phone,
      },
      'C11-D73',
      terms?.id || NaN,
    );
    if (response.user) {
      const { user } = response;
      user.token = response.token;
      user.redirect = '/nordic';
      if (token !== null && token !== undefined) {
        user.mode = 'active-in';
        dispatch(userActions.login(user));
      } else {
        dispatch(userActions.login(user));
      }
      navigate('/nordic');
    } else {
      console.log(response);
    }
  };
  const changePhone = async (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const regex = /^[0-9\b]+$/;
    if (
      event.target.value === '' ||
      (regex.test(event.target.value) && event.target.value.slice(0, 3) === '569' && event.target.value.length <= 11)
    ) {
      setPhone(event.target.value);
      const response = await userService.phoneIsRegistered(event.target.value);
      if (event.target.value.length === 11 && response.user === null) {
        setPhoneError('');
      } else if (event.target.value.length === 11 && response.user !== null) {
        setPhoneError('El telefono ingresado ya está registrado');
      } else {
        setPhoneError('Debe tener 11 digitos');
      }
    } else if (event.target.value.slice(0, 3) !== '569') {
      setPhoneError('Codigo de area invalido');
    } else if (event.target.value.length <= 10) {
      setPhoneError('Debe tener 11 digitos');
    } else if (event.target.value.length !== 12) {
      setPhoneError('Error al ingresar el número');
    }
  };
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  return (
    <Box sx={{ maxWidth: '100vw', minWidth: '310px', display: 'inline-block' }}>
      {activeStep > 0 && (
        <Box sx={{ width: '101%', marginY: '16px' }}>
          <Stepper activeStep={activeStep - 4} alternativeLabel>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>
      )}
      <SwipeableViews disabled index={activeStep}>
        <Box sx={{ width: '100%', paddingX: '5vw' }}>
          <Box
            sx={{ width: '100%', marginTop: '20vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <img src={logo_vrt} height={'100px'} />
          </Box>
          <Box sx={{ width: '100%', marginY: '7vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Typography variant="h5" sx={{ textAlign: 'center', marginY: '2%' }}>
              Registrate para acceder a Activida siempre que lo necesites!
            </Typography>
          </Box>
          <Button
            onClick={handleNext}
            size="large"
            sx={{
              width: '100%',
              marginY: '2%',
              fontWeight: 'bold',
              borderRadius: '50px',
            }}
            variant="contained">
            EMPEZAR MI REGISTRO
          </Button>
        </Box>
        <Box sx={{ paddingX: '5%' }}>
          <Button onClick={handleBack} size="small" sx={{ marginY: '2%', fontWeight: 'bold' }} variant="text">
            {'← Atras'}
          </Button>
          <TextField
            sx={{ width: '100%', marginY: '2%' }}
            label="RUT"
            variant="outlined"
            value={RUT}
            onChange={changeRUT}
            error={RUTError !== ''}
            helperText={RUTError}
          />
          <TextField
            sx={{ width: '100%', marginY: '2%' }}
            label="Telefono celular"
            variant="outlined"
            value={phone}
            inputProps={{ inputMode: 'numeric' }}
            onChange={changePhone}
            error={phoneError !== ''}
            helperText={phoneError}
          />
          <TextField
            value={name}
            onChange={changeName}
            sx={{ width: '100%', marginY: '2%' }}
            label="Nombre"
            variant="outlined"
          />
          <TextField
            value={lastName}
            onChange={changeLastName}
            sx={{ width: '100%', marginY: '2%' }}
            label="Apellido"
            variant="outlined"
          />
          <TextField
            value={password}
            onChange={changePassword}
            sx={{ width: '100%', marginY: '2%' }}
            label="Contraseña"
            type="password"
          />
          <TextField
            value={confirmation}
            onChange={changeConfirmation}
            sx={{ width: '100%', marginY: '2%' }}
            label="Confirma tu contraseña"
            type="password"
            error={passwordError !== ''}
            helperText={passwordError}
          />
          <Stack direction="row" justifyContent="center" alignItems="center" spacing={12}>
            <Button
              disabled={
                RUT === '' ||
                RUTError !== '' ||
                name === '' ||
                lastName === '' ||
                password === '' ||
                confirmation !== password ||
                passwordError !== '' ||
                phone === '' ||
                phone.length !== 11 ||
                phoneError !== ''
              }
              onClick={handleNext}
              size="large"
              sx={{ marginY: '2%', fontWeight: 'bold', borderRadius: '50px' }}
              variant="contained">
              {'Siguiente →'}
            </Button>
          </Stack>
        </Box>
        <Box sx={{ paddingX: '5%' }}>
          <Button onClick={handleBack} size="small" sx={{ marginY: '2%', fontWeight: 'bold' }} variant="text">
            {'← Atras'}
          </Button>
          <TextField
            value={weight}
            onChange={changeWeight}
            sx={{ width: '100%', marginY: '2%' }}
            label="Peso"
            variant="outlined"
            inputProps={{ inputMode: 'numeric' }}
            InputProps={{
              endAdornment: <InputAdornment position="start">kg</InputAdornment>,
            }}
          />
          <TextField
            value={height}
            onChange={changeHeight}
            sx={{ width: '100%', marginY: '2%' }}
            label="Altura"
            variant="outlined"
            inputProps={{ inputMode: 'numeric' }}
            InputProps={{
              endAdornment: <InputAdornment position="start">cm</InputAdornment>,
            }}
          />
          <p>Fecha de nacimiento</p>
          <DateInput onChange={changeBirthday} />
          <FormControl sx={{ width: '100%', marginY: '2%' }}>
            <InputLabel id="Sselect-label">Sexo</InputLabel>
            <Select
              value={sex}
              onChange={changeSex}
              labelId="Sselect-label"
              id="simple-select"
              label="Sexo"
              variant="outlined"
              sx={{ width: '100%' }}>
              <MenuItem value="M">Hombre</MenuItem>
              <MenuItem value="F">Mujer</MenuItem>
              <MenuItem value="-">Prefiero no decir</MenuItem>
            </Select>
          </FormControl>
          <FormGroup>
            <p>
              Marque la casilla correspondiente si ha sido <b>diagnosticado por un médico</b> con alguna de las
              siguientes enfermedades
            </p>
            <FormControlLabel
              onChange={() => setDiabetes(diabetes === false)}
              control={<Checkbox checked={diabetes} />}
              label="Diabetes"
            />
            <FormControlLabel
              onChange={() => setThrombosis(thrombosis === false)}
              control={<Checkbox checked={thrombosis} />}
              label="Antencedentes de Trombosis"
            />
            <FormControlLabel
              onChange={() => setHeartDisease(heartDisease === false)}
              control={<Checkbox checked={heartDisease} />}
              label="Antencedentes de Enfermedades Cardiovasculares"
            />
          </FormGroup>
          <FormControlLabel control={<Checkbox />} label="Ninguna de las anteriores" />
          <TextField
            value={otherDiseases}
            onChange={changeOtherDiseases}
            sx={{ width: '100%', marginY: '2%' }}
            label="Otras enfermedades o antecedentes"
            variant="outlined"
          />
          <Stack direction="row" justifyContent="center" alignItems="center" spacing={12}>
            <Button
              disabled={birthday === null || sex === '' || height === '' || weight === ''}
              onClick={handleNext}
              size="large"
              sx={{ marginY: '2%', fontWeight: 'bold', borderRadius: '50px' }}
              variant="contained">
              {'Siguiente →'}
            </Button>
          </Stack>
        </Box>
        <Box sx={{ paddingX: '5%' }}>
          <Button onClick={handleBack} size="small" sx={{ marginY: '2%', fontWeight: 'bold' }} variant="text">
            {'← Atras'}
          </Button>
          <Terms
            content={`
# TÉRMINOS Y CONDICIONES DE USO
## 1. INTRODUCCIÓN
MAKANA SpA, en adelante “MAKANA”, titular del sitio web https://makana.cl, en adelante el “Sitio web”, y/o aplicaciones móviles “APP MAKANA”, en adelante “Aplicación/es” o “App”. El uso de “APP MAKANA”, en adelante la “Aplicación” y de los servicios especificados, está sujeto a lo estipulado en el presente documento de Términos y Condiciones de Uso, en adelante las “Condiciones de Uso”, las que deberán ser cumplidas por los usuarios (en adelante “Usted”, el “Suscriptor” o el “Usuario”).
Por el sólo hecho del uso de la Aplicación, el Usuario expresa la adhesión plena y sin reservas a todos y cada uno de los términos, condiciones y estipulaciones de las Condiciones de Uso, por lo que por la suscripción del presente usted acepta y otorga su consentimiento expreso a los Términos y Condiciones acá previstos. En caso de que el Usuario no esté de acuerdo con la totalidad de lo establecido en ellas, no estará autorizado para acceder a la información o contenidos de la Aplicación.
El Usuario entiende que la aceptación de los términos aquí contenidos, es equivalente a un contrato escrito y con este protocolo se da cumplimiento expreso a la legislación nacional vigente. Las Condiciones de Uso podrán ser modificadas o adaptadas a cambios legislativos, prácticas generales de la industria, o políticas de MAKANA. En este caso, MAKANA anunciará los cambios introducidos, con la antelación necesaria a su puesta en práctica.
Al utilizar la Aplicación, Usted acepta recibir comunicaciones acerca de temas referidos a su suscripción. Estas comunicaciones pueden incluir el envío de correos electrónicos a la dirección de e-mail indicada durante el registro, el envío de mensajes de texto al teléfono celular señalado en el registro y/o a través de aplicaciones móviles, que incluirán avisos acerca de su cuenta, que forman parte de su relación con MAKANA.
Adicionalmente, Usted acepta recibir comunicaciones de nuestra parte, como nuevas características y contenidos de la Aplicación, ofertas especiales, anuncios promocionales y encuestas de satisfacción por e-mail o por otra vía. Si ya no desea que le enviemos comunicaciones que no son relativas a las transacciones, podrá solicitar que se le elimine de la lista de destinatarios de este tipo de comunicaciones, a través de un correo dirigido a la dirección que se indique para tal efecto.
## 2. CONTENIDO y PROPIEDAD INTELECTUAL
### 2.1. Contenido y Propiedad Intelectual de MAKANA.
Todos los contenidos puestos a disposición en el Sitio Web y/o App, incluyendo pero sin limitarse a código, imágenes, texto, ilustraciones, formato, sonidos, fotos, contenidos audiovisuales, videos, archivos, iconos, tecnologías, software, links, y demás contenidos audiovisuales o sonoros, su diseño gráfico y códigos fuente, así como las marcas, nombres comerciales o signos distintivos, en
 adelante el “Contenido de MAKANA” es de propiedad de MAKANA o han sido patentados a MAKANA y están protegidos por las leyes de derecho de autor, marcas comerciales, patentes, secretos comerciales y/o las demás leyes de Propiedad Intelectual vigentes en Chile o de Derecho Internacional aplicables al caso.
MAKANA no cede, y concede únicamente una licencia de uso para consulta del Contenido de MAKANA, no otorgando ningún otro tipo de licencia o autorización a los Usuarios sobre los derechos de propiedad intelectual correspondientes a los Contenidos de MAKANA.
MAKANA no autoriza la copia, reproducción, transmisión, modificación, creación de trabajos derivados, distribución, exhibición o ejecución pública, disposición, o de cualquier manera la explotación del software y de los servicios del Sitio Web y/o App y/o del resultado audiovisual de la ejecución del software (diseño de pantalla).
Mediante el uso del Sitio Web y/o App, el Usuario acepta que todo el Contenido de MAKANA al que accede será bajo su propio riesgo y será el único responsable por cualquier daño o pérdida que le suceda a el Usuario o a otros a causa de ello.
Todo uso del Contenido de MAKANA que no esté permitido expresamente por estos Términos y Condiciones de Uso constituye un incumplimiento a los presentes y puede infringir las leyes de propiedad intelectual. MAKANA se reserva todo derecho que no esté expresamente otorgado en estos Términos y Condiciones de Uso.
Si el Usuario infringe estos Términos y Condiciones de Uso, queda automáticamente sin efecto el permiso para acceder y utilizar el Contenido de MAKANA y deberá eliminar y/o dejar de hacer uso del Sitio Web y/o App.
El Usuario entiende que ninguno de los Contenidos de MAKANA disponibles en el Sitio web y/o App debe ser considerado como consejo médico o garantía sobre que cualquier medicamento o tratamiento en particular es seguro, apropiado o eficaz para el Usuario y/o sea utilizado como reemplazo o sustitución del diagnóstico o recomendaciones emitidas por un profesional de la salud en el marco de una consulta clínica presencial y/o virtual.
### 2.2 Contenido del Usuario
El Usuario podrá mediante los distintos formularios y chats propuestos tanto en el Sitio Web como en la App, agregar, crear, cargar, enviar información y contenidos que expresamente se autorice, en adelante “Contenido del Usuario”.
Respecto al Contenido del Usuario se concede a MAKANA un permiso y/o autorización no exclusiva para acceder al mismo a través del Sitio Web y/o App, y para transferir, usar, editar, modificar, reproducir, distribuir, preparar trabajos derivados, mostrar y ejecutar dicho Contenido del Usuario.
Asimismo, el Usuario declara y garantiza que tiene todos los derechos para conceder dicho permiso sin infringir ni violar derechos de terceros, incluidos entre otros, derechos a la privacidad, derechos de publicidad, derechos de autor, derechos de propiedad industrial, derechos contractuales o cualquier otro derecho intelectual o de propiedad.

 En este sentido se deja expresa constancia que el Usuario que origine Contenido del Usuario es el exclusivo responsable por el mismo sin que MAKANA pueda garantizar la autenticidad del Contenido del Usuario. Finalmente, el Usuario entiende y acepta que MAKANA no es responsable de ningún error u omisión en cualquier parte del Contenido del Usuario.
## 3. CARACTERÍSTICAS DEL SERVICIO OFRECIDO
3.1. La Aplicación Activida tiene como propósito ofrecer servicios de bienestar y prevención laboral para los usuarios, mediante la entrega de recursos educativos e informativos en formato audiovisual.
3.2. Los servicios que ofrece Activida incluyen:
- Una cápsula de pausa activa guiada, diseñada para promover el bienestar físico a través de ejercicios simples y fáciles de realizar durante la jornada laboral.
- Un video educativo sobre lesiones musculoesqueléticas en el trabajo, cuyo objetivo es proporcionar información para conocer mejor estas lesiones comunes y fomentar la prevención.
- Un video sobre estrés laboral, que ofrece estrategias y herramientas para gestionar el estrés en el ámbito laboral, promoviendo el bienestar emocional y mental.
3.3. Los servicios ofrecidos a través de Activida no sustituyen la atención médica profesional, el diagnóstico ni el tratamiento de salud. En caso de que el Usuario tenga dudas sobre su condición física o de salud, deberá consultar a un profesional médico.
3.4. Activida no ofrece servicios de asistencia médica, diagnósticos, tratamiento o emergencias médicas. Los contenidos tienen un carácter exclusivamente informativo y preventivo.
3.7. Descargo de Responsabilidad Ampliado:
A través del presente acto, el Usuario exonera a Makana de toda responsabilidad por cualquier daño, ya sea directo, indirecto, incidental, consecuente, ejemplar, especial o punitivo, de cualquier naturaleza que pudiera surgir en conexión con el mal uso o la incapacidad de utilizar los servicios proporcionados a través de la aplicación Activida. Esta exoneración abarca, entre otros aspectos, la:
a) Pérdida de Beneficios e Ingresos: Makana no se hace responsable de la pérdida de beneficios económicos o ingresos que pudieran derivarse del uso de los contenidos y servicios de la aplicación, independientemente de la notificación de la posibilidad de dichas pérdidas.
b) Impacto en Negocios: MAKANA no asume responsabilidad por los perjuicios que puedan afectar a la actividad comercial del Usuario, incluyendo pérdida de clientes, reputación o cualquier otra consecuencia relacionada con el negocio.
c) Pérdida de Datos: Makana no se responsabiliza por la pérdida, corrupción o acceso no autorizado a datos almacenados durante el uso de la aplicación, incluyendo cualquier consecuencia negativa asociada a dicha pérdida.

 d) Daños Físicos y Accidentes: Makana queda exenta de responsabilidad por cualquier daño físico, lesión, accidente o cualquier consecuencia relacionada con la seguridad del usuario durante la realización de las actividades sugeridas en la aplicación.
e) Fallecimiento: Se establece que Makana no asume responsabilidad por eventos extremos como el fallecimiento del Usuario relacionado directa o indirectamente con el uso de los contenidos o recomendaciones de la aplicación, independientemente de haber sido notificado o no sobre la posibilidad de tales acontecimientos.
Esta exoneración de responsabilidad es aplicable en toda la extensión permitida por la ley y abarca cualquier reclamo, demanda, acción judicial o procedimiento, ya sea de naturaleza contractual, extracontractual o de cualquier otra índole. El Usuario acepta expresamente que el uso de los contenidos y servicios de Activida es bajo su propio riesgo y que ha leído, comprendido y aceptado plenamente los términos presentes.
## 4. EXENCIÓN DE RESPONSABILIDAD POR EL SERVICIO PRESTADO
MAKANA no se hace responsable de daño o perjuicio alguno que el Usuario sufra como consecuencia o con motivo de la información, tratamiento o rutina entregada en la Aplicación lo cual es expresamente aceptado por el Usuario. El Usuario expresamente declara y acepta que MAKANA se encuentra liberada de toda responsabilidad por daños y perjuicios que provengan de lo anterior.
El acceso a la Aplicación y sus contenidos está condicionado a que el Usuario los utilice de acuerdo a la moral, las buenas costumbres, el orden público, la legislación vigente de propiedad intelectual e industrial, y las presentes Condiciones de Uso de la Aplicación. El Usuario se compromete a no utilizar el Sitio web y/o App con ningún propósito que esté prohibido por estos Términos y Condiciones de Uso como así también por la legislación aplicable
El Usuario acepta y declara que MAKANA no es responsable en caso que el envío de información, desde y hacia la Aplicación, sufra intervenciones o alteraciones por la intervención de terceros; o bien, se vea expuesta a virus, infecciones u otras propiedades que puedan producir contaminación o destrucción. Atendido lo expuesto, el Usuario debe ser consciente de que las medidas de seguridad en Internet no son inexpugnables.
## 5. EXCLUSIÓN DE RESPONSABILIDAD RESPECTO A ENLACES EXTERNOS
5.1. La Aplicación y/o Sitio web puede contener enlaces a aplicaciones web de terceros no relacionados con MAKANA. MAKANA, por tanto, no es responsable por las acciones de estos terceros, incluyendo sus prácticas de privacidad y cualquier contenido publicado en sus aplicaciones web.
5.2. La Aplicación puede contener avisos y patrocinios. Los anunciantes y patrocinadores son responsables de asegurar que el material incluido en la Aplicación sea fiel a su original y que no contravenga al ordenamiento jurídico aplicable. Ni MAKANA ni la Aplicación se responsabilizan de

 cualquier acto u omisión arbitraria o ilegal, de los anunciantes y patrocinadores, ni de cualquier error o imprecisión del material proporcionado por éstos.
## 6. POLÍTICA DE PROTECCIÓN DE DATOS PERSONALES
6.1. La Política de Protección de Datos Personales de la Aplicación y/o Sitio web ha sido establecida según las disposiciones de la Ley 19.628 sobre Protección de la Vida Privada Datos. Esta política tiene como objetivo la protección y el respeto de los derechos de las personas naturales a tener su vida privada y familiar, correspondencia privada y comunicaciones. Esto incluye el derecho a ser informado y tomar decisiones respecto del procesamiento de sus datos personales.
6.2. Conforme a lo anterior, se entenderá por:
a) Datos personales: Cualquier información relativa a una persona natural identificada o
identificable.
b) Datos sensibles: Aquellos datos personales que se refieren a las características físicas o morales de las personas o a hechos o circunstancias de su vida privada o intimidad, tales como lo hábitos personales, el origen racial, las ideologías y opiniones políticas, las creencias o convicciones religiosas, los estados de salud físicos o psíquicos, la vida sexual, la pertenencia a sindicatos, y la información relativa a la comisión o la sospecha de comisión de ofensas o delitos, y los resultados de los respectivos procesos judiciales o de otro tipo.
c) Titular de los datos: La persona natural identificada o identificable a la que se refieren los datos personales.
d) Controlador de datos: La compañía o persona que, por sí misma o junto con otros, es competente de acuerdo con la ley chilena para determinar el propósito y medios de procesamiento de los datos personales.
e) Receptor: cualquier persona natural o jurídica, autoridad pública, agencia o cualquier otra entidad a quien se le revelen los datos personales, sea o no un tercero. Sin embargo, las autoridades que puedan recibir datos personales en el marco de una consulta particular no serán consideradas receptores.
f) Procesamiento de datos personales: cualquier operación o conjunto de operaciones que se realice respecto de los datos personales, sea o no por medios automatizados, que permitan recolectar, almacenar, grabar, organizar, elaborar, adaptar, alterar, seleccionar, usar, extraer, consultar, confrontar, interconectar, disociar, comunicar, ceder, transferir, revelar, diseminar, transmitir, alinear, cancelar, bloquear, borrar o destruir datos personales, o utilizarlos en cualquier otra forma.
g) Transferencia de datos personales: Cualquier transferencia de datos personales que sean objeto de procesamiento o que se pretendan procesar después de su transferencia a través de límites nacionales.
h) Base de datos: Conjunto organizado de datos personales, sea automatizado o no, cualquiera sea la forma o modalidad de su creación u organización, que permita relacionar los datos entre sí, así

 como realizar todo tipo de procesamiento de datos. La visita a la Aplicación o al Sitio web no supone que el Usuario esté obligado a proporcionar a MAKANA sus datos personales. En el caso de que el Usuario proporcione sus datos personales, dichos datos personales recogidos en este Sitio serán utilizados con el propósito, en la forma y con las limitaciones y derechos que recoge la Ley 19.628 sobre Protección de la Vida Privada. Los datos personales suministrados por los Usuarios no serán procesados de ninguna manera incompatible con los propósitos especificados inicialmente, sin informarles a, y obtener nuevamente autorización de, los Usuarios/titulares de los datos personales respectivos. Los datos personales solicitados a los Usuarios serán adecuados, relevantes y no serán excesivos en relación con el propósito específico para el cual fueron solicitados. MAKANA procederá a la cancelación o eliminación de los datos personales recogidos cuando éstos dejen de ser necesarios o pertinentes para el propósito para el que hubiesen sido recabados o registrados
6.3. Al momento de recabar datos personales, el titular de los mismos debe dar a MAKANA, o a la persona natural o jurídica designada por MAKANA, su consentimiento inequívoco antes de que sus datos personales sean procesados. Este consentimiento puede ser obtenido por medios electrónicos, siempre que el mismo sea gratuito e inequívoco. Hacer clic en los botones “siguiente”, “aceptar” o “continuar” en la pantalla será suficiente para entender que el consentimiento ha sido otorgado de manera inequívoca, siempre que también esté el botón “atras”. Los Usuarios que faciliten sus datos personales, consienten inequívocamente la incorporación de los mismos en una base de datos de la que será responsable MAKANA, o una persona natural o jurídica designada por MAKANA, así como del procesamiento o no de los datos personales. Esta información será tratada con sumo cuidado y con la mayor seguridad posible y sólo se utilizará de acuerdo a los límites establecidos en este documento. Los datos personales deberán ser veraces. En el caso de que los datos recogidos se utilizasen para una finalidad distinta para la cual hubiesen sido recabados o recogidos se requerirá el consentimiento previo de los interesados. Makana utiliza los datos personales proporcionados por sus Usuarios para los fines directos por los cuales se entregan, que corresponden a prestar recomendaciones personalizadas a cada uno de los usuarios respecto del uso de los contenidos y servicios de Activida, con la finalidad de prevenir riesgos y fomentar el bienestar del Usuario en el entorno laboral.
6.4. Los datos sensibles no serán procesados por MAKANA excepto si el titular de los mismos da su consentimiento expreso para ello de las formas ya enunciados en punto 6.3 anterior.
MAKANA ha adoptado las medidas técnicas y organizativas necesarias para garantizar la seguridad e integridad de los datos personales, así como para evitar su alteración, pérdida accidental, destrucción, tratamiento o acceso no autorizado. Las medidas adoptadas por MAKANA serán proporcionales a los riesgos involucrados en el procesamiento de los datos personales. No obstante, en la actualidad ningún sistema es completamente seguro o libre de ataques. Los Usuarios cuyos datos personales sean objeto de tratamiento podrán ejercer gratuitamente los siguientes derechos: a) Derecho de acceso: Incluye el derecho a obtener confirmación respecto a si sus datos personales están siendo procesados e información relativa a la naturaleza del procesamiento; el derecho a ser informado del detalle de los datos personales que están siendo procesados y de cualquier información disponible respecto de su fuente; y el derecho a ser informado respecto del “conocimiento de la lógica” involucrado en el procesamiento.

b) Derecho de rectificación, eliminación y bloqueo: El titular de los datos personales tiene derecho a rectificar, eliminar o bloquear los datos personales, cuyo procesamiento no cumpla con la ley aplicable. El controlador de datos notificará a terceros a quienes se le hayan revelado los datos personales de cualquier solicitud de rectificación, eliminación o bloqueo de los mismos por parte de su titular, a menos de que esto sea imposible o implique un esfuerzo desproporcionado.
c) Derecho de objeción: Este derecho incluye el derecho del titular de los datos personales a objetar el procesamiento de los mismos basado en fundamentos legítimos; el derecho a estar informado antes de que sus datos personales sean revelados a cualquier tercero, o usados en su nombre para propósitos de marketing directo; y el derecho a que expresamente se le ofrezca la oportunidad de objetar, de manera gratuita, dichas revelaciones o uso de datos personales.
6.5. Todos estos derechos, más los que concede la Ley N° 19.628, se conceden sin efectos retroactivos en los términos especificados en dicha ley, conforme al procedimiento legalmente establecido. Estos derechos podrán ser ejercitados dirigiendo comunicación vía e-mail a MAKANA a la casilla de correo electrónico hola@makana.cl.
## 7. ACCIONES, COMPETENCIA Y LEGISLACIÓN APLICABLE
MAKANA se reserva el derecho a investigar violaciones a los Términos y Condiciones de Uso de la Aplicación, y de entablar toda acción que estime conveniente en contra de quien resulte responsable, incluyendo, sin estar limitado a, denunciar ante los organismos competentes o a terceros, cualquier actividad considerada ilegal o clandestina, y a revelar cualquier información necesaria o útil para dichos organismos o personas relacionadas con las fichas personales, direcciones de correos electrónicos, historial, material enviado, direcciones IP e información de tráfico de los usuarios.
Para todos los efectos emanados de la presente política, sus términos y uso de la Aplicación, e información y contenidos asociados al mismo, el Usuario fija su domicilio en la ciudad y comuna de Santiago, y se somete a la jurisdicción de sus tribunales ordinarios de justicia. De la misma forma, para todos los efectos, la legislación aplicable será la de la República de Chile.
            `}
          />
          <Box sx={{ marginX: '5vw' }}>
            <FormControlLabel
              sx={{ marginY: '16px' }}
              onChange={() => setAccept(accept === false)}
              control={<Checkbox checked={accept} />}
              label="He leido y acepto los términos y condiciones de uso"
            />
          </Box>
          <Stack direction="row" justifyContent="center" alignItems="center" spacing={12}>
            <Button
              onClick={register}
              size="large"
              sx={{ width: '100%', marginY: '2%', fontWeight: 'bold', borderRadius: '50px' }}
              disabled={
                RUT === '' ||
                RUTError !== '' ||
                name === '' ||
                lastName === '' ||
                password === '' ||
                confirmation !== password ||
                birthday === null ||
                passwordError !== '' ||
                sex === '' ||
                height === '' ||
                weight === '' ||
                accept === false
              }
              variant="contained">
              EMPEZAR
            </Button>
          </Stack>
        </Box>
      </SwipeableViews>
    </Box>
  );
};

export default UserRegister;
