import { CohenState } from '../types/cohenTypes';
import { createSlice } from '@reduxjs/toolkit';
import { Action } from './base';

const initialState: CohenState = {
  question1: NaN,
  question2: NaN,
  question3: NaN,
  question4: NaN,
  question5: NaN,
  question6: NaN,
  question7: NaN,
  question8: NaN,
  question9: NaN,
  question10: NaN,
  postCohenStatus: {
    loading: false,
    success: false,
    error: false,
  },
};

const CohenSlice = createSlice({
  name: 'cohen',
  initialState,
  reducers: {
    updateCohen: (state: CohenState, action: Action) => {
      return {
        ...state,
        [action.payload.name]: action.payload.value,
      };
    },
    resetCohen: () => {
      return {
        ...initialState,
      };
    },
  },
});

export const cohenActions = CohenSlice.actions;
export const cohenReducer = CohenSlice.reducer;
