import React, { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import userService from '../../services/UserServices';
import { useNavigate } from 'react-router-dom';
import { Box, FormControl, Button, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import Rating, { IconContainerProps } from '@mui/material/Rating';
import Typography from '@mui/material/Typography';
import { useDispatch } from 'react-redux';
import { userActions } from '../../redux/slices/userSlice';

const StyledRating = styled(Rating)(({ theme }) => ({
  '& .MuiRating-iconEmpty .MuiSvgIcon-root': {
    color: theme.palette.action.disabled,
  },
}));

const customIcons: {
  [index: string]: {
    icon: React.ReactElement;
    label: string;
  };
} = {
  1: {
    icon: <h1 className="npnm">😩</h1>,
    label: '5',
  },
  2: {
    icon: <h1 className="npnm">😞</h1>,
    label: '4',
  },
  3: {
    icon: <h1 className="npnm">😐</h1>,
    label: '3',
  },
  4: {
    icon: <h1 className="npnm">🙂</h1>,
    label: '2',
  },
  5: {
    icon: <h1 className="npnm">😄</h1>,
    label: '1',
  },
};

function IconContainer(props: IconContainerProps) {
  const { value, ...other } = props;
  return <span {...other}>{customIcons[value].icon}</span>;
}

const RegisterC2: FC = () => {
  const user_id = useSelector((state: RootState) => state.user.id);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [fatigue, setFatigue] = useState(NaN);
  const [musclePain, setMusclePain] = useState(NaN);
  const [feeling, setFeeling] = useState(NaN);

  const register = () => {
    userService.registerOutUser({
      fatigue: 6 - fatigue,
      pain: 6 - musclePain,
      general: 6 - feeling,
      protocol_id: 38,
      user_id: user_id,
    });
    dispatch(userActions.setStarted({ started: undefined }));
    navigate('/trastornos');
  };

  return (
    <FormControl>
      <Box sx={{ margin: '20px' }}>
        <Typography component="legend">
          ¿Qué tan <b>CANSADO</b> te sientes?
        </Typography>
        <StyledRating
          size="large"
          name="highlight-selected-only"
          value={fatigue}
          IconContainerComponent={IconContainer}
          getLabelText={(value: number) => customIcons[value].label}
          highlightSelectedOnly
          onChange={(event: any, newValue: any) => {
            setFatigue(newValue);
          }}
        />
        <Stack direction={'row'} spacing={'50%'}>
          <Typography sx={{ fontSize: 'small', fontWeight: 'bold' }}>Cansado</Typography>
          <Typography sx={{ fontSize: 'small', fontWeight: 'bold' }}>Descansado</Typography>
        </Stack>
      </Box>
      <Box sx={{ margin: '20px' }}>
        <Typography component="legend">
          ¿Cuanta <b>MOLESTIA FISICA</b> tienes?
        </Typography>
        <StyledRating
          size="large"
          name="highlight-selected-only"
          IconContainerComponent={IconContainer}
          getLabelText={(value: number) => customIcons[value].label}
          highlightSelectedOnly
          value={musclePain}
          onChange={(event: any, newValue: any) => {
            setMusclePain(newValue);
          }}
        />
        <Stack direction={'row'} spacing={'50%'}>
          <Typography sx={{ fontSize: 'small', fontWeight: 'bold' }}>Mucha Molestia</Typography>
          <Typography sx={{ fontSize: 'small', fontWeight: 'bold' }}>Sin Molestia</Typography>
        </Stack>
      </Box>
      <Box sx={{ margin: '20px' }}>
        <Typography component="legend">
          ¿Cómo te sientes <b>AHORA</b>?
        </Typography>
        <StyledRating
          size="large"
          name="highlight-selected-only"
          value={feeling}
          IconContainerComponent={IconContainer}
          getLabelText={(value: number) => customIcons[value].label}
          highlightSelectedOnly
          onChange={(event: any, newValue: any) => {
            setFeeling(newValue);
          }}
        />
        <Stack direction={'row'} spacing={'50%'}>
          <Typography sx={{ fontSize: 'small', fontWeight: 'bold' }}>Muy Mal</Typography>
          <Typography sx={{ fontSize: 'small', fontWeight: 'bold' }}>Muy Bien</Typography>
        </Stack>
      </Box>
      {fatigue !== null &&
        musclePain !== null &&
        feeling !== null &&
        isNaN(fatigue) === false &&
        isNaN(musclePain) === false &&
        isNaN(feeling) === false && (
          <Box sx={{ margin: '20px' }}>
            <Button
              onClick={register}
              variant="contained"
              size="large"
              sx={{ borderRadius: '50px', fontWeight: 'bold', width: '100%', backgroundColor: '#78bf00' }}>
              TERMINAR SESION
            </Button>
          </Box>
        )}
    </FormControl>
  );
};

export default RegisterC2;
