import React, { FC, useState, ChangeEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { userActions } from '../../redux/slices/userSlice';
import userService from '../../services/UserServices';
import { Box, TextField, Button } from '@mui/material';

export const formatValue = (value: string) => {
  let regex2;
  let regex1;
  if (value.length >= 2) {
    regex1 = /[^0-9]/g;
    regex2 = /[^0-9k]/g;
    const valueToVerify = value.slice(0, -1).replace(regex1, '');
    const verifyingDigit = value.charAt(value.length - 1).replace(regex2, '');
    const cleanValue = valueToVerify + verifyingDigit;
    return `${cleanValue.slice(0, -1)}-${cleanValue.charAt(cleanValue.length - 1)}`;
  }
  return value;
};

export const dgv = (T: number) => {
  let M = 0;
  let S = 1;
  // eslint-disable-next-line no-param-reassign
  for (; T; T = Math.floor(T / 10)) {
    S = (S + (T % 10) * (9 - (M % 6))) % 11;
    M += 1;
  }
  return S ? S - 1 : 'k';
};

const AddRUT: FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [RUT, setRUT] = useState('');
  const [RUTError, setRUTError] = useState('');
  const changeRUT = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value } = event.target;
    const formattedValue = formatValue(value);
    const checkDigit = dgv(+formattedValue.slice(0, -2));
    let validRut = '';
    if (checkDigit.toString() !== formattedValue.charAt(formattedValue.length - 1)) {
      validRut = 'El RUT ENTREGADO NO ES VALIDO';
    }
    setRUT(formattedValue);
    setRUTError(validRut);
  };
  const activated = () => {
    navigate('/home');
  };
  const token = useSelector((state: RootState) => state.user.token);
  const patchRUT = async () => {
    const response = await userService.updateRUT(RUT, token);
    if (response) {
      const { user } = response;
      user.token = response.token;
      dispatch(userActions.login(user));
      dispatch(userActions.redirect({ redirect: 'false' }));
      activated();
    }
  };
  return (
    <Box sx={{ minWidth: '320px', display: 'inline-block', padding: '5%' }}>
      <Box sx={{ width: '100%', marginY: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <h2 className="App-logo-text">COMPLETA TU INFORMACION</h2>
      </Box>
      <TextField
        sx={{ width: '100%', marginY: '2%' }}
        label="RUT"
        variant="outlined"
        value={RUT}
        onChange={changeRUT}
        error={RUTError !== ''}
        helperText={RUTError}
      />
      <Button
        onClick={patchRUT}
        size="large"
        sx={{ width: '100%', marginY: '2%', fontWeight: 'bold', borderRadius: '50px' }}
        disabled={RUT === '' || RUTError !== ''}
        variant="contained">
        AGREGAR RUT
      </Button>
    </Box>
  );
};

export default AddRUT;
