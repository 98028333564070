import React, { FC, Fragment } from 'react';
import { Box, Fade, Link } from '@mui/material';
import PreviewCard from './PreviewCard';

interface CtaProps {
  url: string;
}

const Cta3: FC<CtaProps> = (props: CtaProps) => {
  return (
    <Link
      sx={{
        color: '#11d43a',
        backgroundColor: 'white',
        minWidth: {
          xs: '60vw',
          sm: '60%',
        },
        borderRadius: '50px',
        fontWeight: 'bold',
        boxShadow: 'none',
        paddingY: '2vh',
        paddingX: '5vw',
        marginX: '5vw',
        textDecoration: 'none',
        whiteSpace: 'nowrap',
        fontSize: '25px',
      }}
      href={`/in/${props.url}`}>
      Empecemos
    </Link>
  );
};

const Cta2: FC<CtaProps> = (props: CtaProps) => {
  return (
    <Link
      sx={{
        color: '#11d43a',
        backgroundColor: 'white',
        minWidth: {
          xs: '60vw',
          sm: '60%',
        },
        borderRadius: '50px',
        fontWeight: 'bold',
        boxShadow: 'none',
        paddingY: '2vh',
        paddingX: '5vw',
        marginX: '5vw',
        textDecoration: 'none',
        whiteSpace: 'nowrap',
        fontSize: '25px',
      }}
      href={`/${props.url}`}>
      Empecemos
    </Link>
  );
};

const PlanCarousel: FC = () => {
  return (
    <Fade in>
      <Box>
        <Box
          alignItems="stretch"
          sx={{
            textAlign: 'left',
            margin: '0',
            padding: '0',
          }}>
          <Box
            sx={{
              marginY: '0',
              paddingX: '5vw',
              paddingY: '0',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-around',
              gap: 3,
              py: 0,
              overflow: 'auto',
              width: '100vw',
              scrollSnapType: 'x mandatory',
              '& > *': {
                scrollSnapAlign: 'center',
              },
              '::-webkit-scrollbar': { display: 'none' },
            }}>
            <Fragment>
              <PreviewCard
                title={'Pausa Activa Guiada'}
                callToAction={<Cta3 url={'no'} />}
                urlParams="continue"
                imgUrl={'https://cdn.makana.cl/mkn-572.jpg'}
                gradient="linear-gradient(to bottom right,#11d43ac0 0%, #92c41fa0 39%, #d1ff0040 100%)"
              />
              <PreviewCard
                title={'Estrés Laboral'}
                callToAction={<Cta2 url={'register-c1'} />}
                urlParams="continue"
                imgUrl={'https://cdn.makana.cl/mkn-572.jpg'}
                gradient="linear-gradient(to bottom right,#11d43ac0 0%, #92c41fa0 39%, #d1ff0040 100%)"
              />
              <PreviewCard
                title={'Trastornos musculoesqueléticos en el trabajo'}
                callToAction={<Cta2 url={'register-c2'} />}
                urlParams="continue"
                imgUrl={'https://cdn.makana.cl/mkn-572.jpg'}
                gradient="linear-gradient(to bottom right,#11d43ac0 0%, #92c41fa0 39%, #d1ff0040 100%)"
              />
            </Fragment>
          </Box>
        </Box>
      </Box>
    </Fade>
  );
};

export default PlanCarousel;
