import React, { FC } from 'react';
import { Fade } from '@mui/material';
import { Box } from '@mui/material';

interface Props {
  title: any;
  callToAction: any;
  urlParams: string;
  imgUrl?: string;
  subtitle?: string;
  gradient: string;
}

const PreviewCard: FC<Props> = (props: Props) => {
  return (
    <Fade in={true}>
      <Box
        sx={{
          borderRadius: '20px',
          backgroundImage: props.imgUrl ? `url(${props.imgUrl})` : 'linear-gradient(to bottom right,#0068ff, #7be7c6)',
          backgroundRepeat: `no-repeat`,
          backgroundSize: `100% auto`,
          backgroundPosition: 'center',
          boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
          minWidth: {
            xs: '90vw', // Dispositivos móviles
            sm: '400px', // Otros tamaños de pantalla
          },
          minHeight: '20vh',
        }}>
        <Box
          sx={{
            borderRadius: '20px',
            backgroundImage: props.gradient,
            width: '100%',
            height: '100%',
            minWidth: {
              xs: '90vw', // Dispositivos móviles
              sm: '400px', // Otros tamaños de pantalla
            },
            minHeight: '20vh',
            margin: '0vh',
            padding: '3vh',
          }}>
          <Box sx={{ marginX: { xs: '5vw', sm: '30px' }, marginBottom: { xs: '5vw', sm: '20px' }, paddingY: '0' }}>
            <h2 className="App-profile-text">{props.title}</h2>
          </Box>
          {props.callToAction}
        </Box>
      </Box>
    </Fade>
  );
};

export default PreviewCard;
