import React, { FC, useState } from 'react';
import { Box, Button } from '@mui/material';
import SwipeableViews from 'react-swipeable-views';
import MobileStepper from '@mui/material/MobileStepper';
import BaseQuestion from '../components/FormComponents/Cohen/BaseQuestion';
import Submit from '../components/FormComponents/Cohen/Submit';
import BaseQuestionReversed from '../components/FormComponents/Cohen/BaseQuestionReversed';
import Intro from '../components/FormComponents/Cohen/Intro';
import { useNavigate } from 'react-router-dom';

const Cohen: FC = () => {
  const [activeStep, setActiveStep] = useState(0);
  const maxSteps = 11;
  const navigate = useNavigate();

  const handleNext = () => {
    setTimeout(() => {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }, 100);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const skip = () => {
    navigate('/home');
  };

  return (
    <Box
      sx={{
        display: 'block',
        width: '100vw',
        height: '90vh',
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: '10vh',
      }}>
      <Box sx={{ overflowY: 'scroll', height: '80vh', textAlign: 'left' }}>
        <SwipeableViews index={activeStep}>
          <Intro />
          <BaseQuestion tag="question1" name="hola" onChange={handleNext} />
          <BaseQuestion tag="question2" name="hola" onChange={handleNext} />
          <BaseQuestion tag="question3" name="hola" onChange={handleNext} />
          <BaseQuestionReversed tag="question4" name="hola" onChange={handleNext} />
          <BaseQuestionReversed tag="question5" name="hola" onChange={handleNext} />
          <BaseQuestion tag="question6" name="hola" onChange={handleNext} />
          <BaseQuestionReversed tag="question7" name="hola" onChange={handleNext} />
          <BaseQuestionReversed tag="question8" name="hola" onChange={handleNext} />
          <BaseQuestion tag="question9" name="hola" onChange={handleNext} />
          <BaseQuestion tag="question10" name="hola" onChange={() => console.log(null)} />
        </SwipeableViews>
      </Box>
      <MobileStepper
        sx={{ background: 'transparent', width: '90vw', marginX: '5vw' }}
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
        nextButton={
          activeStep === maxSteps - 1 ? (
            <Submit />
          ) : (
            <Button
              onClick={handleNext}
              sx={{ fontWeight: 'bold', borderRadius: '50px' }}
              variant="contained"
              size="small"
              aria-label="next">
              Siguiente
            </Button>
          )
        }
        backButton={
          activeStep === 0 ? (
            <Button onClick={skip} sx={{ fontWeight: 'bold' }} size="small" aria-label="back">
              Omitir
            </Button>
          ) : (
            <Button
              onClick={handleBack}
              sx={{ fontWeight: 'bold' }}
              size="small"
              disabled={activeStep === 0}
              aria-label="back">
              Atrás
            </Button>
          )
        }
      />
    </Box>
  );
};

export default Cohen;
