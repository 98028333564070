import { buildInteractorDirectAction, buildInteractorDirectActionNoParams } from './base';
import { cohenActions } from '../slices/cohenSlice';

export const updateCohenInteractor = buildInteractorDirectAction(cohenActions.updateCohen);
export const resetCohenInteractor = buildInteractorDirectActionNoParams(cohenActions.resetCohen);
// export const postRegisterInInteractor = buildInteractor(
//   cohenActions.loadingPostCohen,
//   cohenActions.successPostCohen,
//   cohenActions.errorPostCohen,
//   userService.registerInUser,
// );
