import React, { FC, useState, ChangeEvent, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as NordicFormInteractors from '../../../redux/interactors/NordicFormInteractors';
import { Box } from '@mui/material';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { bodyPartExtra } from '../../../redux/types/nordicFormTypes';
import { es } from '../../../language/es';
import languages from '../../../language/languages';
import ReactMarkdown from 'react-markdown';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';

const TotalTime = {
  Zero: es.nordic.partDetail.zero,
  Week: es.nordic.partDetail.week,
  Month: es.nordic.partDetail.month,
  More: es.nordic.partDetail.more,
  All: es.nordic.partDetail.all,
};

interface StateProps {
  name: string;
  tag: string;
  onChange: (e: boolean) => void;
}

interface DispatchProps {
  updatePartDetailsInteractor: typeof NordicFormInteractors.updatePartDetailsInteractor;
}

interface Props extends StateProps, DispatchProps {
  // extra props you want to add
}

const NordicPartDetail3: FC<Props> = (props: Props) => {
  const { tag } = props;
  const [totalTime, setTotalTime] = useState<string | null>(null);
  const [trauma, setTrauma] = useState<boolean | null>(null);
  const [swelling, setSwelling] = useState<boolean | null>(null);
  const { selected } = useSelector((state: RootState) => state.language);

  const updateStore = (name: string, value: any) => {
    const partDetails: bodyPartExtra = {
      totalTime,
      trauma,
      swelling,
    };
    partDetails[name] = value;
    props.updatePartDetailsInteractor({ name: props.name, value: { ...partDetails } });
  };

  useEffect(() => {
    let next = true;
    if (totalTime === null) {
      next = false;
    }
    if (
      totalTime !== null &&
      totalTime !== '' &&
      totalTime !== es.nordic.partDetail.zero
    ) {
      // prettier-ignore
    }
    props.onChange(next);
  }, [totalTime]);

  useEffect(() => {
    props.onChange(true);
  }, []);

  const handleTotalTime = (event: ChangeEvent<HTMLInputElement>) => {
    setTotalTime(event.target.value);
    updateStore('totalTime', event.target.value);
  };

  const handleTrauma = (event: ChangeEvent<HTMLInputElement>) => {
    setTrauma(event.target.value === 'true');
    updateStore('trauma', event.target.value === 'true');
  };

  const handleSwelling = (event: ChangeEvent<HTMLInputElement>) => {
    setSwelling(event.target.value === 'true');
    updateStore('swelling', event.target.value === 'true');
  };

  return (
    <Box
      sx={{
        overflowY: 'scroll',
        height: '75vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        paddingX: '20px',
      }}>
      <Box
        sx={{
          height: '100%',
          width: '500px',
          maxWidth: '92vw',
          display: 'inline-block',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '12px',
        }}>
        <FormControl>
          <FormLabel sx={{ color: '#1b4ed8 !important' }}>
            <ReactMarkdown className="mdp">{languages[selected].nordic.partDetail.q1(tag)}</ReactMarkdown>
          </FormLabel>
          <RadioGroup
            value={totalTime}
            onChange={handleTotalTime}
            aria-labelledby="demo-radio-buttons-group-label"
            name="radio-buttons-group">
            <FormControlLabel
              value={TotalTime.Zero}
              control={<Radio />}
              label={languages[selected].nordic.partDetail.zero}
            />
            <FormControlLabel
              value={TotalTime.Week}
              control={<Radio />}
              label={languages[selected].nordic.partDetail.week}
            />
            <FormControlLabel
              value={TotalTime.Month}
              control={<Radio />}
              label={languages[selected].nordic.partDetail.month}
            />
            <FormControlLabel
              value={TotalTime.More}
              control={<Radio />}
              label={languages[selected].nordic.partDetail.more}
            />
            <FormControlLabel
              value={TotalTime.All}
              control={<Radio />}
              label={languages[selected].nordic.partDetail.all}
            />
          </RadioGroup>
          {(totalTime === '0 dias' || totalTime === '1 a 7 dias') && (
            <>
              <FormLabel sx={{ color: '#1b4ed8 !important' }}>
                <ReactMarkdown className="mdp">{languages[selected].nordic.partDetail.q2}</ReactMarkdown>
              </FormLabel>
              <RadioGroup
                value={trauma}
                onChange={handleTrauma}
                aria-labelledby="demo-radio-buttons-group-label"
                name="radio-buttons-group">
                <FormControlLabel value={true} control={<Radio />} label={languages[selected].nordic.partDetail.yes} />
                <FormControlLabel value={false} control={<Radio />} label={languages[selected].nordic.partDetail.no} />
              </RadioGroup>
              <FormLabel sx={{ color: '#1b4ed8 !important' }}>
                <ReactMarkdown className="mdp">{languages[selected].nordic.partDetail.q3}</ReactMarkdown>
              </FormLabel>
              <RadioGroup
                value={swelling}
                onChange={handleSwelling}
                aria-labelledby="demo-radio-buttons-group-labfel"
                name="radio-buttons-group">
                <FormControlLabel value={true} control={<Radio />} label={languages[selected].nordic.partDetail.yes} />
                <FormControlLabel value={false} control={<Radio />} label={languages[selected].nordic.partDetail.no} />
              </RadioGroup>
            </>
          )}
        </FormControl>
      </Box>
    </Box>
  );
};

const mapDispatchToProps = (dispatch: any): DispatchProps => ({
  ...bindActionCreators(
    {
      ...NordicFormInteractors,
    },
    dispatch,
  ),
});

export default connect(null, mapDispatchToProps)(NordicPartDetail3);
