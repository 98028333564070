import React, { FC } from 'react';
import { Box } from '@mui/material';
import RegisterC2 from '../components/FormComponents/RegisterC2';

const RegisterC2Screen: FC = () => {
  return (
    <Box
      sx={{
        height: '100%',
        minHeight: '100vh',
        width: '100vw',
        textAlign: 'left',
      }}>
      <Box
        sx={{
          height: '100%',
          minHeight: '100vh',
          width: '100vw',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}>
        <Box sx={{ paddingY: '10vh' }}>
          <RegisterC2 />
        </Box>
      </Box>
    </Box>
  );
};

export default RegisterC2Screen;
