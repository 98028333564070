import React, { FC, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { RootState } from '../../redux/store';
import { useSelector, connect } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { userActions } from '../../redux/slices/userSlice';
import userService from '../../services/UserServices';
import * as protocolInteractors from '../../redux/interactors/ProtocolInteractors';
import * as userInteractors from '../../redux/interactors/UserInteractors';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { Card, CardHeader, CardMedia } from '@mui/material';
import { Drawer, Typography, Stack } from '@mui/material';
import { Button, IconButton, Chip } from '@mui/material';
import StartIcon from '@mui/icons-material/Start';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import AccessibilityIcon from '@mui/icons-material/Accessibility';
import CloseIcon from '@mui/icons-material/Close';
import './Protocol.css';

interface DispatchProps {
  unselectProtocolInteractor: typeof protocolInteractors.unselectProtocolInteractor;
  saveProtocolInteractor: typeof userInteractors.saveProtocolInteractor;
  unsaveProtocolInteractor: typeof userInteractors.unsaveProtocolInteractor;
}

const RoutinePreview: FC<DispatchProps> = (props: DispatchProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { token } = useSelector((state: RootState) => state.user);
  const protocolHidden = useSelector((state: RootState) => state.protocol.protocolHidden);
  const protocol = useSelector((state: RootState) => state.protocol.protocol);
  const filter = useSelector((state: RootState) => state.filter);
  const protocolId = protocol ? protocol.id : NaN;
  const tags = filter.filters.filter((f) => f.protocolIds.includes(protocolId));

  const toggleDrawer = () => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }
    props.unselectProtocolInteractor();
  };

  const redirection = async (token: string) => {
    const response = await userService.getRedirect(token);
    if (response) {
      const { redirect } = response;
      dispatch(userActions.redirect({ redirect }));
    } else {
      dispatch(userActions.redirect({ redirect: false }));
    }
  };

  useEffect(() => {
    // Run! Like go get some data from an API.
    if (token) {
      redirection(token);
    }
  }, []);

  const form = () => {
    const now = new Date();
    dispatch(userActions.setMode({ mode: 'active-in' }));
    dispatch(userActions.setStarted({ started: now.toISOString() }));
    navigate('/in/no');
  };

  return (
    <Drawer
      sx={{ zIndex: '10200', width: '100vw' }}
      PaperProps={{ elevation: 0, style: { backgroundColor: 'transparent' } }}
      anchor="bottom"
      open={protocolHidden === false}
      onClose={toggleDrawer()}>
      <Card sx={{ width: '100vw', height: '50vh', borderRadius: '25px 25px 0px 0px' }}>
        <div className="scroll-box">
          <CardMedia component="img" height="194" image={protocol?.imgUrl} alt="Paella dish" />
          <CardHeader
            action={
              <IconButton aria-label="settings" onClick={toggleDrawer()}>
                <CloseIcon />
              </IconButton>
            }
            title={
              <Typography variant="h5" color="#505050">
                {protocol?.title}
              </Typography>
            }
          />
          <Stack direction="row" sx={{ margin: '10px' }}>
            <Button
              onClick={form}
              variant="contained"
              endIcon={<StartIcon />}
              sx={{
                backgroundImage: 'linear-gradient(to bottom right,#78bf00, #7be7c6)',
                fontWeight: 'bold',
                width: '80%',
                marginX: '10%',
                borderRadius: '50px',
              }}>
              REALIZAR RUTINA
            </Button>
          </Stack>
          <Stack
            direction="column"
            spacing={1}
            sx={{ marginTop: '20px', marginBottom: '80px', overflowY: 'auto', overflowX: 'hidden' }}>
            <Stack direction="column" sx={{ minWidth: '50%', width: '100vw', maxWidth: '100vw', overflow: 'hidden' }}>
              <Stack
                justifyContent="flex-start"
                alignItems="flex-start"
                direction="row"
                spacing={1}
                sx={{ marginLeft: '10px' }}
                display="inline-block">
                <Chip color="info" sx={{ marginY: '5px' }} label={protocol?.time} icon={<AccessTimeFilledIcon />} />
                <Chip color="info" sx={{ marginY: '5px' }} label={protocol?.bodyPart} icon={<AccessibilityIcon />} />
                {tags.map((tag) => (
                  <Chip color="info" sx={{ marginY: '5px' }} key={`t-${tag.id}`} label={tag.name} />
                ))}
              </Stack>
            </Stack>
            <Typography>
              {protocol?.steps?.map((step, index) => (
                <Typography key={index} variant="body2" sx={{ margin: '7%', padding: '0px' }}>
                  <span className="md">
                    <ReactMarkdown remarkPlugins={[remarkGfm]}>{step.content}</ReactMarkdown>
                  </span>
                </Typography>
              ))}
            </Typography>
          </Stack>
        </div>
      </Card>
    </Drawer>
  );
};

const mapDispatchToProps = (dispatch: any): DispatchProps => ({
  ...bindActionCreators(
    {
      ...protocolInteractors,
      ...userInteractors,
    },
    dispatch,
  ),
});

export default connect(null, mapDispatchToProps)(RoutinePreview);
