import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../redux/store';
import { Box } from '@mui/material';
import PlanCarousel from '../components/WorkoutPlan/PlanCarousel';

const Home: FC = () => {
  const name = useSelector((state: RootState) => state.user.first_name);
  const sex = useSelector((state: RootState) => state.user.sex);
  return (
    <Box sx={{ paddingTop: '10vh' }}>
      <Box sx={{ textAlign: 'left', marginX: '5vw', marginY: '0', paddingY: '0' }}>
        <h2 className="App-logo-text">Hola, {sex === 'F' ? `${name}! 👩‍🚀` : `${name}! 👨‍🚀`}</h2>
      </Box>
      <PlanCarousel />
    </Box>
  );
};

export default Home;
