import React, { FC } from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Stack from '@mui/material/Stack';
import { Box } from '@mui/material';
import '../components/OtherComponents/Protocol.css';
import ConsoltoButton from '../components/OtherComponents/ConsoltoButton';

const Estres: FC = () => {
  return (
    <Card sx={{ width: '100vw', height: '100%', paddingTop: '7vh' }}>
      <CardHeader title={<h3 className="App-logo-text">Estrés laboral</h3>} />
      <div className="scroll-box">
        <Stack
          direction="column"
          spacing={1}
          sx={{ marginTop: '10px', marginBottom: '80px', overflowY: 'auto', overflowX: 'hidden' }}>
          <Box sx={{ display: 'inline', witdh: '100vw', height: '60vh' }}>
            <iframe
              width="100%"
              height="100%"
              src="https://www.youtube.com/embed/sC6kIT2y_g8?si=4SN4tJ-O-nizZaM7"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; modestbranding; gyroscope; picture-in-picture"
              allowFullScreen
              title="Embedded youtube"
            />
          </Box>
          <Box sx={{ display: { xs: 'block', sm: 'block', md: 'inline' }, width: '100vw', overflow: 'auto' }}>
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={1}>
              <Stack direction="column" sx={{ minWidth: '50%', width: '100vw', maxWidth: '100vw', overflow: 'hidden' }}>
                <Stack direction="row" sx={{ margin: '10px' }}>
                  <ConsoltoButton />
                </Stack>
              </Stack>
            </Stack>
          </Box>
        </Stack>
      </div>
    </Card>
  );
};

export default Estres;
