import React, { FC } from 'react';
import { RootState } from '../../../redux/store';
import { useSelector } from 'react-redux';
import { Box, Typography } from '@mui/material';
import { ReactComponent as YourSvg } from '../../../images/nordico.svg';
import languages from '../../../language/languages';
import ReactMarkdown from 'react-markdown';

const NordicFormIntro: FC = () => {
  const { first_name } = useSelector((state: RootState) => state.user);
  const { selected } = useSelector((state: RootState) => state.language);
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100vw',
        overflow: 'visible',
      }}>
      <Box
        sx={{
          display: 'block',
          justifyContent: 'center',
          alignItems: 'center',
          overflow: 'visible',
        }}>
        <Box sx={{ marginTop: '5vh', marginX: '20vw', width: '60vw', maxWidth: '300px', maxHeight: '50vh' }}>
          <YourSvg />
        </Box>
        <Box sx={{ marginX: '5vw', width: '90vw' }}>
          <Typography variant="h6" sx={{ fontWeight: 'bold', textAlign: 'center' }}>
            {languages[selected].nordic.intro.title} {first_name}!!
          </Typography>
          <Typography variant="h6" sx={{ marginTop: '20px' }}>
            <ReactMarkdown className="mdp">{languages[selected].nordic.intro.body}</ReactMarkdown>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default NordicFormIntro;
