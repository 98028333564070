import { AxiosResponse } from 'axios';
import { axiosBaseInstance } from './config';

interface CohenPostFields {
  question1: number;
  question2: number;
  question3: number;
  question4: number;
  question5: number;
  question6: number;
  question7: number;
  question8: number;
  question9: number;
  question10: number;
  user_id: number | undefined;
}

const postForm = async (cohen: CohenPostFields): Promise<any> => {
  const response: AxiosResponse<any> = await axiosBaseInstance({
    headers: { 'Content-Type': 'application/json' },
    method: 'post',
    url: `/cohens`,
    data: {
      cohen,
    },
  });
  return response?.data;
};

const cohenServices = {
  postForm,
};

export default cohenServices;
