import React, { FC, useState, ChangeEvent } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Box } from '@mui/material';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import languages from '../../../language/languages';
import ReactMarkdown from 'react-markdown';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import * as CohenInteractors from '../../../redux/interactors/CohenInteractors';

interface DispatchProps {
  updateCohenInteractor: typeof CohenInteractors.updateCohenInteractor;
}

interface StateProps {
  name: string;
  tag: string;
  onChange: (e: boolean) => void;
}

interface Props extends StateProps, DispatchProps {
  // extra props you want to add
}

const BaseQuestionReversed: FC<Props> = (props: Props) => {
  const { tag } = props;
  const [totalTime, setTotalTime] = useState<string | null>(null);
  const { selected } = useSelector((state: RootState) => state.language);

  const handleTotalTime = (event: ChangeEvent<HTMLInputElement>) => {
    setTotalTime(event.target.value);
    props.updateCohenInteractor({ name: props.tag, value: event.target.value });
    props.onChange(true);
  };

  return (
    <Box
      sx={{
        overflowY: 'scroll',
        height: '75vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        paddingX: '20px',
      }}>
      <Box
        sx={{
          height: '100%',
          width: '100%',
          maxWidth: '92vw',
          display: 'inline-block',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '12px',
        }}>
        <FormControl>
          <FormLabel sx={{ color: '#1b4ed8 !important', paddingBottom: '5vh' }}>
            <ReactMarkdown className="mdp">{languages[selected].cohen[tag]}</ReactMarkdown>
          </FormLabel>
          <RadioGroup
            value={totalTime}
            onChange={handleTotalTime}
            aria-labelledby="demo-radio-buttons-group-label"
            name="radio-buttons-group">
            <FormControlLabel value={4} control={<Radio />} label={languages[selected].cohen.option1} />
            <FormControlLabel value={3} control={<Radio />} label={languages[selected].cohen.option2} />
            <FormControlLabel value={2} control={<Radio />} label={languages[selected].cohen.option3} />
            <FormControlLabel value={1} control={<Radio />} label={languages[selected].cohen.option4} />
            <FormControlLabel value={0} control={<Radio />} label={languages[selected].cohen.option5} />
          </RadioGroup>
        </FormControl>
      </Box>
    </Box>
  );
};

const mapDispatchToProps = (dispatch: any): DispatchProps => ({
  ...bindActionCreators(
    {
      ...CohenInteractors,
    },
    dispatch,
  ),
});

export default connect(null, mapDispatchToProps)(BaseQuestionReversed);
