import React, { FC, useEffect, useState } from 'react';
import { RootState } from '../../../redux/store';
import { useSelector, connect } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { Button } from '@mui/material';
import userService from '../../../services/UserServices';
import * as NordicFormInteractors from '../../../redux/interactors/NordicFormInteractors';
import { useDispatch } from 'react-redux';
import { userActions } from '../../../redux/slices/userSlice';
import languages from '../../../language/languages';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

interface StateProps {
  // add state props here
  disabled: boolean;
}

interface DispatchProps {
  resetNordicFormInteractor: typeof NordicFormInteractors.resetNordicFormInteractor;
}

interface Props extends StateProps, DispatchProps {
  // extra props you want to add
}

const NordicSubmit: FC<Props> = (props: Props) => {
  const navigate = useNavigate();
  const user_id = useSelector((state: RootState) => state.user.id);
  const [userId, setUserId] = useState<number | undefined>(user_id);
  const { selected } = useSelector((state: RootState) => state.language);
  const query = useQuery();
  const phone = query.get('phone') || '';

  const fetchUser = async () => {
    const response = await userService.phoneIsRegistered(phone);
    if (response.user !== null) {
      setUserId(response.user.id);
    }
  };

  useEffect(() => {
    if (user_id === undefined || user_id === null) {
      fetchUser();
    }
  }, []);
  const dispatch = useDispatch();
  const {
    firstCheck,
    weekCheck,
    weekScale,
    lowerBackDetails,
    neckDetails,
    shouldersDetails,
    elbowsExtra,
    handsExtra,
    upperBackExtra,
    legsExtra,
    kneesExtra,
    anklesExtra,
  } = useSelector((state: RootState) => state.nordicForm);
  const postNordic = async () => {
    const response = await userService.postNordic(
      firstCheck.cuello,
      firstCheck.hombro,
      firstCheck.brazoCodo,
      firstCheck.manoMuñeca,
      firstCheck.espaldaAlta,
      firstCheck.espaldaBaja,
      firstCheck.caderaPierna,
      firstCheck.rodilla,
      firstCheck.tobilloPie,
      weekCheck.cuello,
      weekCheck.hombro,
      weekCheck.brazoCodo,
      weekCheck.manoMuñeca,
      weekCheck.espaldaAlta,
      weekCheck.espaldaBaja,
      weekCheck.caderaPierna,
      weekCheck.rodilla,
      weekCheck.tobilloPie,
      firstCheck.espaldaBaja,
      firstCheck.cuello,
      firstCheck.hombro,
      weekScale?.cuello,
      weekScale?.hombro,
      weekScale?.brazoCodo,
      weekScale?.manoMuñeca,
      weekScale?.espaldaAlta,
      weekScale?.espaldaBaja,
      weekScale?.caderaPierna,
      weekScale?.rodilla,
      weekScale?.tobilloPie,
      lowerBackDetails?.hospitalized,
      lowerBackDetails?.changeJobs,
      lowerBackDetails?.totalTime,
      lowerBackDetails?.workActivity,
      lowerBackDetails?.leisureActivity,
      lowerBackDetails?.preventedTime,
      lowerBackDetails?.medicalCare,
      lowerBackDetails?.weekProblems,
      lowerBackDetails?.trauma,
      lowerBackDetails?.swelling,
      neckDetails?.hospitalized,
      neckDetails?.changeJobs,
      neckDetails?.totalTime,
      neckDetails?.workActivity,
      neckDetails?.leisureActivity,
      neckDetails?.preventedTime,
      neckDetails?.medicalCare,
      neckDetails?.weekProblems,
      neckDetails?.trauma,
      neckDetails?.swelling,
      shouldersDetails?.hospitalized,
      shouldersDetails?.changeJobs,
      shouldersDetails?.totalTime,
      shouldersDetails?.workActivity,
      shouldersDetails?.leisureActivity,
      shouldersDetails?.preventedTime,
      shouldersDetails?.medicalCare,
      shouldersDetails?.weekProblems,
      shouldersDetails?.trauma,
      shouldersDetails?.swelling,
      elbowsExtra?.totalTime,
      elbowsExtra?.trauma,
      elbowsExtra?.swelling,
      handsExtra?.totalTime,
      handsExtra?.trauma,
      handsExtra?.swelling,
      upperBackExtra?.totalTime,
      upperBackExtra?.trauma,
      upperBackExtra?.swelling,
      legsExtra?.totalTime,
      legsExtra?.trauma,
      legsExtra?.swelling,
      kneesExtra?.totalTime,
      kneesExtra?.trauma,
      kneesExtra?.swelling,
      anklesExtra?.totalTime,
      anklesExtra?.trauma,
      anklesExtra?.swelling,
      userId,
    );
    if (response.status === 200) {
      //
    }
    props.resetNordicFormInteractor();
    dispatch(userActions.redirect({ redirect: 'false' }));
    if (phone) {
      navigate(`/cohen?phone=${phone}`);
    } else {
      navigate('/cohen');
    }
  };
  return (
    <Button
      disabled={props.disabled}
      onClick={postNordic}
      sx={{ borderRadius: '50px', fontWeight: 'bold', boxShadow: 'none' }}
      variant="contained"
      size="small">
      {languages[selected].nordic.submit}
    </Button>
  );
};

const mapDispatchToProps = (dispatch: any): DispatchProps => ({
  ...bindActionCreators(
    {
      ...NordicFormInteractors,
    },
    dispatch,
  ),
});

export default connect(null, mapDispatchToProps)(NordicSubmit);
