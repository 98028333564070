import React, { FC, useEffect, useState } from 'react';
import { RootState } from '../../../redux/store';
import { useSelector, connect } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { Button } from '@mui/material';
import { useDispatch } from 'react-redux';
import { userActions } from '../../../redux/slices/userSlice';
import languages from '../../../language/languages';
import cohenServices from '../../../services/CohenServices';
import * as CohenInteractors from '../../../redux/interactors/CohenInteractors';
import userService from '../../../services/UserServices';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

interface StateProps {
  // add state props here
}

interface DispatchProps {
  resetCohenInteractor: typeof CohenInteractors.resetCohenInteractor;
}

interface Props extends StateProps, DispatchProps {
  // extra props you want to add
}

const Submit: FC<Props> = (props: Props) => {
  const user_id = useSelector((state: RootState) => state.user.id);
  const [userId, setUserId] = useState<number | undefined>(user_id);
  const { selected } = useSelector((state: RootState) => state.language);
  const query = useQuery();
  const phone = query.get('phone') || '';

  const fetchUser = async () => {
    const response = await userService.phoneIsRegistered(phone);
    if (response.user !== null) {
      setUserId(response.user.id);
    }
  };

  useEffect(() => {
    if (user_id === undefined || user_id === null) {
      fetchUser();
    }
  }, []);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    question1,
    question2,
    question3,
    question4,
    question5,
    question6,
    question7,
    question8,
    question9,
    question10,
  } = useSelector((state: RootState) => state.cohen);
  const postNordic = async () => {
    const response = await cohenServices.postForm({
      question1,
      question2,
      question3,
      question4,
      question5,
      question6,
      question7,
      question8,
      question9,
      question10,
      user_id: userId,
    });
    if (response.status === 200) {
      //
    }
    props.resetCohenInteractor();
    dispatch(userActions.redirect({ redirect: 'false' }));
    navigate('/home');
  };
  return (
    <Button
      disabled={
        (+question1 >= 0 ||
          +question2 >= 0 ||
          +question3 >= 0 ||
          +question4 >= 0 ||
          +question5 >= 0 ||
          +question6 >= 0 ||
          +question7 >= 0 ||
          +question8 >= 0 ||
          +question9 >= 0 ||
          +question10 >= 0) === false
      }
      onClick={postNordic}
      sx={{ borderRadius: '50px', fontWeight: 'bold', boxShadow: 'none' }}
      variant="contained"
      size="small">
      {languages[selected].nordic.submit}
    </Button>
  );
};

const mapDispatchToProps = (dispatch: any): DispatchProps => ({
  ...bindActionCreators(
    {
      ...CohenInteractors,
    },
    dispatch,
  ),
});

export default connect(null, mapDispatchToProps)(Submit);
