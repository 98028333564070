import React, { FC, useState, ChangeEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import userService from '../../services/UserServices';
import { userActions } from '../../redux/slices/userSlice';
import { Box, TextField, Button } from '@mui/material';
import { dgv, formatValue } from './AddRUT';
import logo_vrt from './../../images/logo_vrt.png';

const UserLogin: FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [RUT, setRUT] = useState('');
  const [RUTError, setRUTError] = useState('');
  const [password, setPassword] = useState('');
  const [loginError, setLoginError] = useState('');
  const changeRUT = async (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value } = event.target;
    const formattedValue = formatValue(value);
    const checkDigit = dgv(+formattedValue.slice(0, -2));
    let validRut = '';
    if (checkDigit.toString() !== formattedValue.charAt(formattedValue.length - 1)) {
      validRut = 'El RUT entregado no es válido';
    }
    setRUT(formattedValue);
    setRUTError(validRut);
  };
  const changePassword = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setPassword(event.target.value);
  };
  const login = async () => {
    try {
      let response: any = null;
      response = await userService.loginUser({ rut: RUT, password });
      if (response) {
        const { user } = response;
        user.token = response.token;
        user.redirect = response.redirect;
        dispatch(userActions.login(user));
      } else {
        setLoginError('Contraseña y/o email incorrectos, intenta denuevo');
        setPassword('');
      }
    } catch (error) {
      setLoginError('Contraseña y/o email incorrectos, intenta denuevo');
      setPassword('');
    }
  };

  const recover = () => {
    navigate(`/recover_password`);
  };

  const register = () => {
    navigate('/register');
  };

  return (
    <Box sx={{ minWidth: '320px', display: 'inline-block', padding: '7%' }}>
      <Box sx={{ width: '100%', marginY: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <img src={logo_vrt} width={'150px'} />
      </Box>
      <TextField
        sx={{ width: '100%', marginY: '2%' }}
        label="RUT"
        variant="outlined"
        value={RUT}
        onChange={changeRUT}
        error={RUTError !== ''}
        helperText={RUTError}
      />
      <TextField
        sx={{ width: '100%', marginY: '2%' }}
        label="Contraseña"
        type="password"
        autoComplete="current-password"
        value={password}
        onChange={changePassword}
        error={loginError !== ''}
        helperText={loginError}
      />
      <Button
        onClick={login}
        size="large"
        sx={{ width: '100%', marginY: '2%', fontWeight: 'bold', borderRadius: '50px' }}
        variant="contained">
        INICIAR SESION
      </Button>
      <Box sx={{ marginTop: '5vh', paddingY: '3vh', borderTop: '1px solid #DDD' }}>
        <Button
          type="button"
          onClick={recover}
          size="large"
          sx={{ width: '100%', marginY: '5%', fontWeight: 'bold' }}
          variant="text">
          Recupera tu contraseña
        </Button>
        <Button
          type="button"
          onClick={register}
          size="large"
          sx={{ width: '100%', marginY: '0%', fontWeight: 'bold' }}
          variant="text">
          REGISTRATE ACÁ
        </Button>
      </Box>
    </Box>
  );
};

export default UserLogin;
